.blockcart {
  a {
    color: #666;
  }
}

.leo-dropdown-cart {
    .leo-cart-dropdown-action {
      a.btn {
        &.cart-dropdow-checkout {
          background: #373839 !important;
          border: 2px solid #444546 !important;
          color: #fff !important;

          &:hover, &:active, &:focus {
            background: #444546 !important;
            border: 2px solid #444546 !important;
            color: #fff !important;
          }
        }
      }
    }

    .leo-dropdown-bottom {
      .leo_free_price {
        font-size: small;
        padding: 0 10px;
      }
    }
}
