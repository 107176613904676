#g_customfields_content_box_1 {
  .g_customfields_content {
    .checkbox_item_wp {
      float: left !important;
      margin-right: 10px !important;
      .row {
        > p {
          margin: 0 !important;

          label {
            display: none !important;
            margin: 0 !important;
          }
        }
      }
    }
  }
}