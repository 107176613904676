.box-products {
  padding: 0 !important;
  .products_block, .product_list  {
    margin-bottom: 0 !important;
    .title_block {
      font-size: 28px;
      margin-bottom: 60px;
    }

    .block_content {
      .thumbnail-container {
        .product-image {
          .product-flags {
            .product-flag {
              &.on-sale {
                span {
                  background: $mainColor;
                }
              }
            }
          }
        }

        .product-price-and-shipping {
          font-family:"Averta W03 Bold1583716", sans-serif;
        }
      }
    }
  }
}

.product_list {
  .thumbnail-container {
    .product-image {
      text-align: center;
    }
  }
}

.product-flags {
  .product-flag {
    &.on-sale {
      span {
        background: $mainColor;
      }
    }
  }
}