.btn {
  font-family: 'Averta W03 Regular1585930', sans-serif !important;

  &:hover, &:focus, &:active {
    background: #444546!important;
    color: #fff;
  }

  &.btn-default {
    background-color: $mainColor;
    border-color: $mainColor;
    color: white;
  }

  &.btn-primary {
    background: #373839;
    color: #fff;

    &:hover, &:focus, &:active {
      background: #444546 !important;
      color: #fff;
    }
  }

  &.btn-outline {
    background: none !important;
    color: #373839 !important;
    border: 2px solid !important;

    &:hover, &:focus, &:active {
      background: #efefef !important;
      color: #373839 !important;
      border: 2px solid !important;
    }
  }
}

.input-group {
  .input-group-btn {
    > .btn {
      line-height: 3;

      &:hover, &:focus, &:active{
        background-color: $mainColorDark;
        border-color: $mainColorDark;
        color: white;
      }
    }
  }
}