#checkout {
  section.checkout-step {
    #customer-form {
      > section {
        .form-group {
          &:last-of-type,
          &:nth-last-of-type(2) {
            margin: 0;
            .col-md-6, .col-md-3 {
              width: 100% !important;
              padding: 0;
              margin: 0;
            }
          }
        }
      }
    }
    &#checkout-personal-information-step {

    }

    .add-address a {
      &:hover {
        color: initial !important;

        .material-icons {
          background: #292929 !important;
        }
      }
    }

    &#checkout-payment-step {
      .payment-option {
        label {
          text-align: left !important;
        }
      }
    }
  }

  .custom-checkbox {
    label {
      font-size: 16px !important;
      color: #666 !important;

      input[type="checkbox"],
      input[type="radio"] {
        + span {
          float: left;
          width: 13px !important;
          height: 13px !important;
          border-radius: 3px;
        }
      }
    }
  }

}
