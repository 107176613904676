.block-categories {
  .category-sub-menu {
    li {
      > a:hover, a:focus, a:active {
        color: $mainColor;
      }

      .navbar-toggler[aria-expanded="true"] + :after {
        background-color: $mainColor;
      }

      &:hover, &:focus, &:active {
        &:after {
          background-color: $mainColorDark;
        }
      }
    }
  }
}

.plist-dsimple {
  .thumbnail-container{
    &:hover {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1) !important;
    }
  }
}

.widget-category_image {
  .block_content {
    > ul {
      a {
        .cate_content {
          .cover-img {
            img {
              width: auto !important;
              height: 100px !important;
            }
          }

          span {
            color: #000;
          }
        }

        &:hover {
          .cate_content {
            .cover-img {
              img {
                filter: none !important;
                transform: none !important;
              }
            }
          }
        }
      }
      @media (min-width: 1200px) {
        width: 33.3333% !important;
      }
    }
  }
}