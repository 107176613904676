body {
  &#cms  {
    .page-content.page-cms {
      text-align: left !important;
    }
    nav.breadcrumb {
      display: none !important;
    }

    header.page-header {
      margin-bottom: 0 !important;
      > h1 {
        display: none !important;
      }
    }

    &.cms-id-17 {
      #wrapper {
        padding-top: 0px !important;

        #content {
          p:first-child {
            display: none;
          }
        }
      }
    }
  }
}