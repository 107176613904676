.leo-megamenu {
  .navbar-nav {
    text-align: left;
    > li {
      > a {
        font-family: 'Averta W03 Bold1583716', sans-serif !important;
      }
    }

    .nav-item {
      &.hidden {
        .nav-link {
          padding: 0 !important;
        }
      }
    }
  }

  .dropdown-menu {
    li {
      a {
        font-size: 14px !important;
        line-height: 20px !important;
      }
    }
  }
}