/* REGISTRATION FORM */

#authentication {
  &.page-authentication {
    .register-form {
      .custom-checkbox {
        label {
          padding: 0 !important;
          font-size: 12px;
          color: #878787;

          input[type="checkbox"] + span {
            width: 13px;
            height: 13px;
            border-radius: 3px;
          }

          p {
            margin: 0 !important;
          }
        }
      }

      #customer-form {
        > section {
          .form-group {
            &:last-of-type,
            &:nth-last-of-type(2) {
              margin-bottom: 10px !important;
            }
          }
        }
      }
    }
  }
}


/* PASSWORD RESET */
#password {
  &.page-password {
    #content {
      max-width: 640px;
      margin: 0 auto;

      .forgotten-password {
        .form-control-submit.btn {
          margin: 10px 15px;

          @media (max-width: 1199px) {
            margin: 10px 9px;
          }
        }

        .row > .col-md-3,
        .row > .col-md-5 {
          width: 100%;
          text-align: left;
          padding-top: 0;
        }
      }
    }

    .page-footer {
      max-width: 640px;
      margin: 0 auto ;
    }
  }
}

