body {
  font-family: 'Averta W03 Regular1585930', sans-serif;
  font-size: 16px;

  &#index {
    background-color: $bodyBg;
  }
}

a {
  color: $mainColor;

  &:not(.fa) {
    font-family: 'Averta W03 Regular1585930', sans-serif !important;
  }

  &:hover, &:focus, &:active {
    color: $mainColorDark;
  }
}

span {
  font-family: 'Averta W03 Regular1585930', sans-serif !important;
}

h1, .h1 {
  font-family:"Averta W03 Semibold1589050", sans-serif !important;
}

h2, .h2, h3, .h3, h4, .h4, h5, .h4, h6, .h6 {
  font-family: 'Averta W03 Bold1583716', sans-serif !important;
}

.display-4 {
  font-size: 4rem;
  font-weight: 400;
}

.ApColumn .title_block {
  font-size: 28px;
}

.topnav {
  .shipping-image {
    text-align: center;

    img {
      max-width: 500px;
    }
  }
}

/* BOX-NAVH1 */
.box-navh1 {
  .top-block-menu {
    display: inline-block;

    ul {
      > li {
        position: relative;
        display: inline-block;
        margin-right: 10px;

        &:not(:last-child) {
          padding: 15px 15px 15px 0px;

          &:after {
            content: "";
            position: absolute;
            width: 1px;
            height: 12px;
            background: #a8a8a8;
            top: 18px;
            right: 0;
          }
        }
      }
    }
  }
  .popup-over {
    &.language-selector-wrapper {
      .popup-content {
        &.dropdown-menu {
          li {
            a {
              color: #000 !important;

              &:hover, &:focus, &:active {
                color: $bodyColor !important;
              }
            }

            &.current {
              a {
                color: $bodyColor !important;
              }
            }
          }
        }
      }
    }
    a {
      &.popup-title {
        i:first-child {
          display: inline-block !important;
        }
      }
    }
  }

  a {
    font-size: 12px !important;
    color: #a8a8a8 !important;

    &:hover, &:focus, &:active {
      color: #fff !important;
    }
  }
}

/* BOX TOP */

.box-toph1 {
  .h-logo {
    padding: 0 !important;
  }

   #search_widget {
     a.popup-title {
       color: #666 !important;
     }
   }
}

/* SLIDESHOW */
.ApSlideShow {
  .bannercontainer {
    .tp-caption {
      color: $bodyColor;
      font-family: 'Averta W03 Regular1585930', sans-serif !important;
      text-transform: none !important;

      &.big_black {
        color: $bodyColor;
        font-family:"Averta W03 Semibold1589050", sans-serif !important;
        text-transform: none;
      }

      &.medium_text {
        color: #666 !important;
        font-weight: 400 !important;
        line-height: 1.5 !important;
        font-size: 20px;
      }

      &.btn {
        font-family:"Averta W03 Semibold1589050", sans-serif !important;
        font-size: 16px;
        color: #fff !important;

        &:hover, &:active, &:focus {
          background-color: #444546 !important;
          border-color: #444546 !important;
        }

      }
    }

    .iview {
      .iview-directionNav {
        .iview-nextNav, .iview-prevNav {
          &:hover {
            &:before {
              background-color: $mainColorDark;
            }
          }
        }
      }
    }
  }
}


#wrapper {
  .breadcrumb {
    &:before {
      background: #f7f7f7 !important;
    }
    ol {
      li {
        a {
          text-transform: none !important;
          font-weight: 400 !important;
        }

        span {
          font-size: 14px !important;
          color: #888;
        }
      }
    }
  }

  /* BOX BANNER */
  .box-banner {
    .ApImage {
      z-index: 5;
      @media (min-width: 992px) and (max-width: 1199px) {
        margin-top: -70px !important;
      }

      @media (min-width: 1200px) {
        margin-top: -100px !important;
      }
    }

    h4, .h4 {
      font-size: 21px;
      position: relative;
      padding-bottom: 15px;
      margin-bottom: 30px;

      &:after {
        content: "";
        bottom: 0px;
        left: 0;
        width: 80px;
        height: 2px;
        background-color: black;
        position: absolute;
      }
    }
  }
}

/* SERVICES */
.service {
  border: none !important;
  .h4, h4 {
    font-size: 21px !important;
    margin-top: 30px !important;
    text-transform: none !important;
  }
}

/* FOOTER */
footer {
  font-size: 14px;

  .box-ftoph1 {
    a {
      &:hover {
        color: #fff;
      }
    }
  }

  .footer-bottom {
    a {
      color: #aaaaaa;
      text-decoration: underline;
    }
  }

  a {
    font-size: 14px;
  }

  p {
    font-family: 'Averta W03 Regular1585930', sans-serif;
  }
}

/* BACK TO TOP */
#back-top {
  background: $mainColor;

  &:hover {
    background: $mainColorDark;
  }
}

.leo-fly-cart-icon-wrapper {
  padding: 15px;

  a {
    color: #000;

    &:hover {
      color: #000 !important;
    }
  }
}

.alert {
  &:before {
    top: 0 !important;
  }
}

#blockcart-modal {
  .close {
    color: #fff !important;
    text-shadow: none !important;
    font-weight: normal !important;
    opacity: 1 !important;
  }
}




.modal {
  .modal-dialog {
    .modal-content {
      .js-modal-content {
        padding: 1rem;

      }

      .close {
        //font-size: 2rem !important;
        //padding: 10px !important;
      }
    }
  }
}

@media (min-width: 992px) {
  .dropdown-sub {
    &.dropdown-menu {
      .dropdown-menu-inner {
        .columns-3 {
          .widget-subcategories {
            ul {
              columns: 3 !important;
            }
          }
        }
      }
    }
  }
}

#pagenotfound {
  .page-not-found {
    h1 {
      color: initial !important;
    }

    .btn {
      &:hover {
        color: #fff;
        background: #444546 !important;
      }
    }
  }
}



